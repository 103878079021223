// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Button from 'common/components/Button';
import TitleBlock from 'marketing/components/TitleBlock';
import DesignerQuote from 'marketing/components/DesignerQuote';
import ViewingUnit from 'marketing/components/ViewingUnit';
import CategoryPrizeSwiper from 'marketing/components/CategoryPrizeSwiper';

// Styles
import styles from './styles.module.css';

const renderPrizeImages = primaryPrizeImages => {
  if (primaryPrizeImages.length === 1) {
    return (
      <div
        className={styles.prizeImage}
        style={
          primaryPrizeImages[0].image && {
            backgroundImage: `url(${primaryPrizeImages[0].image})`
          }
        }
      />
    );
  }

  return (
    <CategoryPrizeSwiper
      prizes={primaryPrizeImages.map(({ image }) => ({
        prizeImage: image,
        imageClassName: styles.prizeImage
      }))}
      customParams={{
        slidesPerView: 1,
        containerClass: `swiper-container ${styles.swiperContainerPadding}`
      }}
    />
  );
};

const renderSuppliers = suppliers =>
  suppliers.map(supplier => (
    <>
      {supplier.contribution && <p className={styles.contributedBy}>{supplier.contribution}</p>}
      <img className={styles.logo} src={supplier.logo} alt={supplier.imageAltText} />
    </>
  ));

const extraClassStyling = button =>
  button.comingSoonText
    ? `${styles.sidebarButton} ${styles.commingSoonButton}`
    : styles.sidebarButton;

const GrandPrizeDetails = ({ data, cashPrize }) => {
  const {
    heading,
    subheading,
    description,
    supportText,
    imageAltText,
    showhomeBlockTitle,
    showhomeViewingHours,
    showhomeAddressText,
    mapImage,
    contribution,
    supplierLogo,
    primaryPrizeButtons,
    primaryPrizeImages,
    suppliers,
    quote
  } = data;

  return (
    <>
      {primaryPrizeImages && primaryPrizeImages.length > 0 && renderPrizeImages(primaryPrizeImages)}
      <div className={styles.details}>
        <div className="l-col-8">
          {heading && (
            <h2 className={`${styles.main} headingFont theme-color-primary`}>{heading}</h2>
          )}

          {subheading && <p className={`${styles.sub} h4 theme-color-primary`}>{subheading}</p>}

          {description && (
            <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: description }} />
          )}

          {supportText && (
            <div className="wysiwyg" dangerouslySetInnerHTML={{ __html: supportText }} />
          )}

          {(showhomeBlockTitle || showhomeViewingHours || showhomeAddressText) && (
            <div className="l-col-12">
              <ViewingUnit
                showhomeBlockTitle={showhomeBlockTitle}
                showhomeViewingHours={showhomeViewingHours}
                showhomeAddressText={showhomeAddressText}
                mapImage={mapImage}
              />
            </div>
          )}

          {quote && (
            <div className="l-col-12">
              <DesignerQuote {...quote} />
            </div>
          )}
        </div>

        <div className={`${styles.sidebar} l-col-3 l-col-offset-1`}>
          {primaryPrizeButtons &&
            primaryPrizeButtons.map(button => (
              <Button
                to={!button.comingSoonText && (button.mediaPath || button.url || button.pdf)}
                text={button.comingSoonText || button.buttonText}
                key={button.buttonText}
                modifiers="secondary sm-small"
                extraClasses={extraClassStyling(button)}
                external={!button.mediaPath}
              />
            ))}

          {supplierLogo && (
            <>
              {contribution && <p className={styles.contributedBy}>{contribution}</p>}
              <img className={styles.logo} src={supplierLogo} alt={imageAltText} />
            </>
          )}
          {suppliers && suppliers.length > 0 && renderSuppliers(suppliers)}
        </div>

        {cashPrize && (
          <div className="l-col-12">
            <TitleBlock
              mainHeading={cashPrize.cashPrizeHeading}
              subHeading={cashPrize.cashPrizeSubheading}
            />
          </div>
        )}
      </div>
    </>
  );
};

GrandPrizeDetails.propTypes = {
  data: PropTypes.shape({
    contribution: PropTypes.string,
    description: PropTypes.string,
    heading: PropTypes.string,
    image: PropTypes.string,
    imageAltText: PropTypes.string,
    mapImage: PropTypes.string,
    primaryPrizeButtons: PropTypes.arrayOf(
      PropTypes.shape({
        buttonText: PropTypes.string,
        pdf: PropTypes.string
      })
    ),
    showhomeAddressText: PropTypes.string,
    showhomeBlockTitle: PropTypes.string,
    showhomeViewingHours: PropTypes.string,
    subheading: PropTypes.string,
    supplierLogo: PropTypes.string,
    supportText: PropTypes.string
  }).isRequired,
  cashPrize: PropTypes.shape({
    cashPrizeHeading: PropTypes.string,
    cashPrizeSubheading: PropTypes.string
  }).isRequired
};

export default GrandPrizeDetails;
