// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './styles.module.scss';

const DesignerQuote = ({ text, name, company, jobTitle, profileImage, imageAltText }) => (
  <div className={styles.designerQuote}>
    {profileImage && imageAltText && (
      <div className={styles.imageContainer}>
        <img className={styles.profileImage} src={profileImage} alt={imageAltText} />
      </div>
    )}
    <div className={styles.quoteInfo}>
      {text && <h4 className="theme-color-primary" dangerouslySetInnerHTML={{ __html: text }} />}

      <div className={styles.designerInfo}>
        {name && <h6>{name}</h6>}
        {jobTitle && <p>{jobTitle}</p>}
        {company && <p>{company}</p>}
      </div>
    </div>
  </div>
);

DesignerQuote.propTypes = {
  profileImage: PropTypes.string,
  imageAltText: PropTypes.string,
  text: PropTypes.string,
  name: PropTypes.string,
  jobTitle: PropTypes.string,
  company: PropTypes.string
};

DesignerQuote.defaultProps = {
  profileImage: '',
  imageAltText: '',
  text: '',
  name: '',
  jobTitle: '',
  company: ''
};

export default DesignerQuote;
