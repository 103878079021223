// Libraries
import React from 'react';

// Component
import Button from 'common/components/Button';

const CategorySlidePrize = (prize, i) => {
  const { heading, subheading, supplierName, prizeImage, pageUrl, imageClassName } = prize;

  return (
    <div className="swiper-slide" key={i}>
      <div
        className={imageClassName || 'swiper__image'}
        style={prizeImage && { backgroundImage: `url(${prizeImage})` }}
      />
      {supplierName && heading && subheading && pageUrl && (
        <div className="swiper__copy">
          <Button
            to={pageUrl}
            text="More Info"
            modifiers="secondary"
            extraClasses="swiper__button u-uppercase"
          />
          <p className="h3 alt theme-color-primary">{heading}</p>
          <p className="h5 theme-color-primary">{subheading}</p>
          <div className="p" dangerouslySetInnerHTML={{ __html: supplierName }} />
        </div>
      )}
    </div>
  );
};

export default CategorySlidePrize;
