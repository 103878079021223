// Libraries
import React from 'react';
import PropTypes from 'prop-types';

// Components
import Swiper from 'react-id-swiper';
import CategoryPrizeSlide from 'marketing/components/CategoryPrizeSlide';

const CategoryPrizeSwiper = ({ prizes, customParams }) => {
  const params = {
    containerClass: 'swiper-container swiper--category-prize l-margin-bottom',
    loop: true,
    slidesPerView: 1.6,
    loopedSlides: 2,
    grabCursor: true,
    centeredSlides: true,
    simulateTouch: false,
    pagination: {
      el: '.swiper-pagination',
      dynamicBullets: true,
      clickable: true,
      renderBullet(index, className) {
        return `<span class="theme-background-secondary ${className}"></span>`;
      }
    },
    // Responsive breakpoints
    breakpoints: {
      // when window width is <= 480px
      480: {
        slidesPerView: 1.1
      }
    },
    navigation: {
      nextEl: '.swiper-button-next.theme-background-secondary.theme-background-secondary',
      prevEl: '.swiper-button-prev.theme-background-secondary.theme-background-secondary',
      clickable: true
    },
    renderPrevButton: () => (
      <div className="swiper-button-prev theme-background-secondary swiper-button-prev--arrow-left">
        <i className="material-icons">arrow_right_alt</i>
      </div>
    ),
    renderNextButton: () => (
      <div className="swiper-button-next theme-background-secondary">
        <i className="material-icons">arrow_right_alt</i>
      </div>
    ),
    ...customParams
  };

  return <Swiper {...params}>{prizes.map(CategoryPrizeSlide)}</Swiper>;
};

CategoryPrizeSwiper.propTypes = {
  prizes: PropTypes.arrayOf(
    PropTypes.shape({
      blockType: PropTypes.string,
      grouping: PropTypes.string,
      heading: PropTypes.string,
      pageUrl: PropTypes.string,
      prizeImage: PropTypes.string,
      subheading: PropTypes.string,
      supplierName: PropTypes.string
    })
  ),
  customParams: PropTypes.objectOf(PropTypes.any)
};

CategoryPrizeSwiper.defaultProps = {
  prizes: [],
  customParams: {}
};

export default CategoryPrizeSwiper;
